import React from 'react'
import Layout from '../components/layout'
import { Theme } from '@pga/pga-component-library'

export default ({ pageContext, location, data }) => {
  const { html, frontmatter } = pageContext

  return (
    <Layout {...{ location, frontmatter, subNavFixed: true, bgColor: Theme.colors.bgGrey }}>
      <div dangerouslySetInnerHTML={{ __html: html || '' }} />
    </Layout>
  )
}
